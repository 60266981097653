import {
	Anchor,
	AnchorClient,
	ActiveAnchor,
	AnchorWithElement,
	PageAnchorsObserverComp,
	ActiveAnchorObserverComp,
} from './types'
import { BrowserWindow } from '@wix/thunderbolt-symbols'

const TOP_ANCHOR_COMP_ID = 'PAGE_TOP_ANCHOR'
const TOP_ANCHOR_DATA_ID = 'SCROLL_TO_TOP'

export const updatePageAnchorsObservers = (
	pageAnchorsObservers: Array<PageAnchorsObserverComp>,
	activeAnchorObservers: Array<ActiveAnchorObserverComp>,
	activeAnchor: ActiveAnchor,
	anchorsWithElements: Array<AnchorWithElement>,
	pageId: string,
	shouldSetPageAnchors: boolean
) => {
	const propsToUpdate: {
		[compId: string]: { activeAnchor: ActiveAnchor; anchors?: Array<AnchorClient> }
	} = {}
	const reducedAnchors: Array<AnchorClient> = anchorsWithElements
		? anchorsWithElements.map((anchorWithElement) => ({
				compId: anchorWithElement.compId,
				dataId: anchorWithElement.dataId,
				name: anchorWithElement.name,
		  }))
		: []

	if (activeAnchorObservers.length) {
		activeAnchorObservers.forEach((comp: ActiveAnchorObserverComp) => {
			propsToUpdate[comp.id] = { activeAnchor }
		})
	}

	if (pageAnchorsObservers.length) {
		pageAnchorsObservers.forEach((comp: PageAnchorsObserverComp) => {
			propsToUpdate[comp.id] = { activeAnchor }
			// Set anchors only to VerticalAnchorsMenu component, and only on init
			if (shouldSetPageAnchors && reducedAnchors.length) {
				const { pageTopLabel, hiddenAnchorIds } = comp.compData
				const pageHiddenAnchorIds: Array<string> =
					hiddenAnchorIds && hiddenAnchorIds.hasOwnProperty(pageId)
						? (hiddenAnchorIds as Record<string, Array<string>>)[pageId]
						: []
				const topAnchor: AnchorClient = {
					compId: TOP_ANCHOR_COMP_ID,
					dataId: TOP_ANCHOR_DATA_ID,
					name: pageTopLabel || '',
				}
				propsToUpdate[comp.id].anchors = [topAnchor]
					.concat(reducedAnchors)
					.filter((anchor) => !pageHiddenAnchorIds.includes(anchor.compId))
			}
		})
	}

	return propsToUpdate
}

const convertAnchorToActiveAnchor = (anchor: Anchor): ActiveAnchor => {
	return {
		compId: anchor.compId,
		dataId: anchor.dataId,
	}
}
export const getActiveAnchor = (anchors: Array<Anchor>, offset: number, window: BrowserWindow): ActiveAnchor => {
	const isBottomOfPage = window!.innerHeight + window!.scrollY >= window!.document.body.scrollHeight
	if (isBottomOfPage) {
		return anchors[anchors.length - 1]
	}
	const nextActiveAnchorIndex = anchors.findIndex((anchor: Anchor) => Math.floor(anchor.top - offset) > 0)
	let activeAnchor: ActiveAnchor
	if (nextActiveAnchorIndex === -1) {
		// all anchors above viewport - return last anchor
		activeAnchor = convertAnchorToActiveAnchor(anchors[anchors.length - 1])
	} else if (nextActiveAnchorIndex === 0) {
		// active anchor is in the top of the page
		activeAnchor = { compId: TOP_ANCHOR_COMP_ID, dataId: TOP_ANCHOR_DATA_ID }
	} else {
		activeAnchor = convertAnchorToActiveAnchor(anchors[nextActiveAnchorIndex - 1])
	}
	return activeAnchor
}
